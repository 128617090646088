import useDebounceSearch from '../../../../hooks/debounceSearch';
import GisogdDocumentsProxy from '../../proxies/gisogdDocumentsProxy';

export default function useDocPreviousVersion(documentTypeId, documentRegNum) {
  const callback = (filter) =>
    GisogdDocumentsProxy.getDocumentsAllowedToCancel(documentTypeId, filter, documentRegNum);
  const { items, fetchWithDelay } = useDebounceSearch(callback);

  return {
    previousVersionDocs: items,
    fetchWithDelay,
  };
}
