import instance from "../../../api.js";

class GisogdDocumentsProxy {
  static async getDocumentsAllowedToCancel(
    documentTypeId,
    filter,
    documentRegNum = null,
    limit = 10
  ) {
    const params = new URLSearchParams({
      docTypeId: documentTypeId,
      filter,
      limit,
    });
    if (documentRegNum) params.append("regNum", documentRegNum);
    return await instance.get(`/gisogd-documents/allowed-to-cancel?`, {
      params,
    });
  }

  static async getDocumentsAllowedToChange(documentTypeId, filter, limit = 10) {
    const params = new URLSearchParams({
      docTypeId: documentTypeId,
      filter,
      limit,
    });
    return await instance.get(`/gisogd-documents/allowed-to-change?`, {
      params,
    });
  }

  static async GetVectorsFromDocIzRsOriginRegistration(registrationNumbers) {
    return await instance.get(
      `gisogd-documents/docIzRs/vectors/${registrationNumbers.join()}`
    );
  }
}

export default GisogdDocumentsProxy;
