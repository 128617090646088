<template>
  <div>
    <UniversalSelectField
        :modelValue="modelValue.docStatus || defaultDocumentStatus"
        @update:modelValue="update('docStatus', $event)"
        :options="documentStatusRefs"
        :keyProp="'id'"
        :displayProp="'displayName'"
    >
      <label class="label label--required">Статус действия</label>
    </UniversalSelectField>
    <TextField
      title="Наименование документа"
      :modelValue="modelValue.docName"
      @update:modelValue="update('docName', $event, v$.docName)"
      :errors="v$.docName.$errors"
      data-qa="name"
    >
      <label class="label" for="docName">Наименование документа</label>
    </TextField>

    <TextField
        :modelValue="modelValue.docNum"
        @update:modelValue="update('docNum', $event, v$.docNum)"
        :errors="v$.docNum.$errors"
        data-qa="number"
    >
      <label class="label label--required" for="docNum">Номер документа</label>
    </TextField>

    <DateField
        :modelValue="modelValue.docDate"
        @update:modelValue="update('docDate', $event, v$.docDate)"
        :errors="v$.docDate.$errors"
        :max="maxDate"
        data-qa="documentDate"
    >
      <label class="label label--required">Дата документа</label>
    </DateField>

    <slot name="approval-authority-field">
      <SubjectField
          :modelValue="modelValue.orgName"
          @update:modelValue="update('orgName', $event, v$.orgName)"
          mode="Organization"
          data-qa="OrganizationName"
          :errors="v$.orgName.$errors"
      >
        <template #label
        ><label
            class="label"
            for="registration-request-person-who-sent"
        >
          Наименование органа, утвердившего документ
        </label></template
        >
      </SubjectField>
    </slot>
    <MultiSelectField
      :modelValue="modelValue.canceledDocuments"
      @update:modelValue="update('canceledDocuments', $event)"
      :options="previousVersionDocs"
      @reload="fetchDocPreviousVersion"
      data-qa="previousVersion"
      keyProp="registrationNumber"
      :itemValidationRule="validationRule"
    >
      <label class="label"
        >Отменяет действие документов</label
      >
      <template #add>Добавить</template>
    </MultiSelectField>

    <slot name="additional-fields"></slot>

    <TextField
      :modelValue="modelValue.note"
      @update:modelValue="update('note', $event, v$.note)"
      :errors="v$.note.$errors"
      data-qa="note"
    >
      <label class="label" for="note">Примечание</label>
    </TextField>
    <slot name="additional-fields-bottom"></slot>
  </div>
</template>

<script>
import {computed, toRefs, watch} from "vue";
import  { useRegistrationProcessStatusRefs } from "@/hooks/registrationProcessRefs";
import useDocPreviousVersion from "./hooks/docPreviousVersion";
import useValidator from "./validator";
import { helpers, required, minValue, maxLength, maxValue } from "@vuelidate/validators";
import DocBase from "../../../models/documents/docBase";
import TextField from "../../basic/form/TextField.vue";
import DateField from "../../basic/form/DateField.vue";
import SubjectField from "../../basic/form/SubjectField.vue";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import subjectValidators from '../../../models/subjectValidators';
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  components: {
    UniversalSelectField,
    TextField,
    DateField,
    SubjectField,
    MultiSelectField,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocBase(),
    },
    documentTypeId: {
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false,
      default: null
    },
    validationRules: {
      type: Object,
      default: () => {
        return {
          docNum: {
            maxLength: helpers.withMessage("Количество символов не должно превышать 70", maxLength(70)),
            required: helpers.withMessage("Заполните поле", required),
          },
          docDate: {
            minValue: helpers.withMessage(
                "Дата должна быть позднее 01.01.1970",
                minValue(new Date(1970, 0, 1))
            ),
            maxValue: helpers.withMessage("Дата документа не должна быть позднее сегодняшнего дня", maxValue(new Date(new Date().setHours(23, 59, 59)))),
            required: helpers.withMessage("Заполните поле", required),
          },
          docName: { maxLength: helpers.withMessage("Количество символов не должно превышать 1000", maxLength(1000)), },
          note: { maxLength: helpers.withMessage("Количество символов не должно превышать 4000", maxLength(4000)), },
          orgName:{...subjectValidators},
        }
      }
    }
  },
  setup(props, {emit}) {
    const {documentStatusRefs} = useRegistrationProcessStatusRefs();
    const defaultDocumentStatus = computed(() => {
      return documentStatusRefs.value.find((s) => s.id == 1);
    });
    const {
      previousVersionDocs,
      fetchWithDelay: fetchDocPreviousVersion,
    } = useDocPreviousVersion(props.documentTypeId, props.documentRegNum);

    const {modelValue: data, validationRules: rules} = toRefs(props);

    const currentDate = new Date();
    const maxDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`

    const v$ = useValidator(data, rules.value);

    let validationRule = {
      registrationNumber: helpers.withMessage(
        "Заполните или удалите неиспользуемое поле",
        (value) => {
          return value !== null && value["registrationNumber"] !== undefined
        }
      ),
    }

    watch(
        () => defaultDocumentStatus.value,
        () => {
          if (
              !props.modelValue.docStatus &&
              defaultDocumentStatus.value !== undefined
          ) {
            update("docStatus", defaultDocumentStatus.value);
          }
        }
    );

    function update(fieldName, value, field) {
      const newValue = {...props.modelValue};
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    return {
      //other
      documentStatusRefs,
      defaultDocumentStatus,
      update,
      previousVersionDocs,
      fetchDocPreviousVersion,
      v$,
      maxDate,
      validationRule
    };
  },
};
</script>
<style scoped>
/* todo копипаста */
.add-subject__icon {
  margin-right: 5px;
}

.add-subject:hover .add-subject__icon path {
  stroke: var(--basic-black);
}

.add-subject {
  position: relative;
  padding-top: 4px;
  padding-bottom: 7px;
  box-sizing: border-box;
}

.add-subject::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--basic-grey-light);
  bottom: -6px;
}
</style>