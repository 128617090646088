import { ref } from 'vue';
import _debounce from 'lodash.debounce';

export default function useDebounceSearch(callback, debounceDelay = 1000) {
  var debounceFunc = undefined;
  const items = ref([]);

  fetch();

  async function fetch(filter = '') {
    items.value = await callback(filter);
  }

  async function fetchWithDelay(filter = '') {
    if (debounceFunc != undefined) debounceFunc.cancel();
    debounceFunc = _debounce(async () => {
      await fetch(filter);
    }, debounceDelay);
    debounceFunc();
  }

  return {
    items,
    fetchWithDelay,
  };
}
